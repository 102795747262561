<template>
  <div class="upload-file flex-center-v">
    <template v-if="!isView">
      <el-upload
          v-loading="loading"
          :show-file-list="false"
          :on-success="handleSuccessConfirm"
          :before-upload="beforeUpload"
          :on-error="onError"
          :action="upLoadUrl"
          class="upload-demo"
      >
        <el-button type="primary" style="width: 50px;padding: 12px 0;">上传</el-button>
      </el-upload>
      <el-input v-model="fileName" disabled style="margin: 0 5px" :title="fileName"></el-input>
      <el-button icon="el-icon-delete" @click="fileName = ''" style="width: 50px;padding: 12px 0;"></el-button>
    </template>
    <template v-else>
      <el-link v-if="fileName" type="primary" :underline="false" :title="fileName" :href="fileName" target="_blank">{{ fileName }}</el-link>
      <el-link v-else type="primary" :underline="false">暂未上传文件</el-link>
    </template>
  </div>
</template>

<script>
import BaseURL from "@/apis/baseurl";

export default {
  name: "upload-file",

  components: {},

  props: {
    value: {
      type: String,
      default: ''
    },
    isView: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false
    }
  },

  computed: {
    fileName: {
      set(val){
        this.$emit('input', val)
      },
      get() {
        return this.value
      }
    },
    upLoadUrl() {
      return BaseURL + '/admin/public/uploadFile'
    }
  },

  watch: {},

  // mounted() {
  // this.init()
  // },

  methods: {
    beforeUpload() {
      this.loading = true
    },
    onError() {
      this.loading = false
    },
    handleRemoveConfirm(){
      this.changeDoStatusForm.confirmationSheet = ''
    },
    handleSuccessConfirm(response) {
      this.loading = false
      this.fileName = response.data
    },
  }
}
</script>

<style lang="stylus" scoped>
.upload-file {
  .el-link {
    width 100%
    >>>.el-link--inner {
      width 100%
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>

<template>
  <div class="one-xy content flex-column">
    <div class="flex-space-between">
      <div class="operation">
        <el-button v-if="optionItem && optionItem.departmentType == 'project'" type="primary" size="small" style="width:80px;" @click="add">新增吊篮</el-button>
        <el-button v-if="optionItem && optionItem.departmentType == 'project'" type="primary" size="small" style="width:80px;" @click="copyAdd">复制新增</el-button>
        <el-button type="primary" size="small" style="width:80px;" @click="edit">修改吊篮</el-button>
        <el-button type="primary" size="small" style="width:120px;" @click="editOne">修改一台一册</el-button>
        <el-button type="primary" size="small" style="width:120px;" @click="clickNotifier">修改检查通知人</el-button>
        <el-button type="danger" size="small" style="width:80px;" @click="dismantle">拆除吊篮</el-button>
        <!--              <el-button type="danger" size="small" style="width:80px;" @click="detele">删除吊篮</el-button>-->
      </div>
      <div class="search">
        <el-input v-model="SearchValue.location" placeholder="吊篮位置" size="small" style="width:120px;margin-right:15px;"></el-input>
        <el-input v-model="SearchValue.model" placeholder="吊篮型号" size="small" style="width:120px;margin-right:15px;"></el-input>
        <el-select v-model="SearchValue.hangingBasketType" placeholder="吊篮类型" size="small" style="width:120px;margin-right:15px;">
          <el-option label="全部类型" value=""></el-option>
          <el-option
              v-for="item in options2"
              :key="item.value + 'aaa'"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
        <el-button type="primary" size="small" style="width:80px;" @click="search">查询</el-button>
      </div>
    </div>
    <div class="cont flex-1-auto">
      <el-table
          ref="multipleTable"
          :data="tableData"
          height="100%"
          :header-cell-style="{background:'#f5f5f5',color:'#333333'}"
          @selection-change="selectItem"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <!--              <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>-->
        <el-table-column align="center" label="吊篮位置">
          <template slot-scope="{ row }">
            <el-link type="primary" @click="handClick(row)">{{ row.location }}</el-link>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="{ row }">
            <div class="red" v-if="row.doStatus===1">停用</div>
            <div class="green" v-else-if="row.doStatus===0">启用</div>
            <div class="yellow" v-else>审批中</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="model" label="吊篮型号" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" prop="hangingBasketTypeStr" label="吊篮类型" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" prop="hangingBasketStatusStr" label="巡检状态" :show-overflow-tooltip="true">
          <template slot-scope="{ row }">
                  <span v-if="row.hangingBasketStatusStr == '待提交' || row.hangingBasketStatusStr == '正常'" class="green">
                    {{ row.hangingBasketStatusStr }}
                  </span>
            <span v-if="row.hangingBasketStatusStr == '维修中' || row.hangingBasketStatusStr == '拆除'" class="red">
                    {{ row.hangingBasketStatusStr }}
                  </span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ratedLoad" label="额定荷载" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" prop="installWayStr" label="安装方式" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" prop="vendor" label="吊篮租赁单位" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" prop="installUnit" label="吊篮安装单位" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" prop="siteHead" label="维修工名称" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" prop="departmentName"  label="编制机构" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="center" label="检查通知人" :show-overflow-tooltip="true">
          <template slot-scope="{ row }">
            <span>{{ row.userNameList.join(',') }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="二维码">
          <template slot-scope="{ row }">
            <el-button type="primary" size="mini" plain @click="topPrint(row)">打印</el-button>
            <!-- <el-button type="primary" size="mini" plain @click="toImage(row)">打印</el-button> -->
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="250">
          <template slot-scope="{ row }">
            <el-button type="success" v-if="row.doStatus===1" size="mini" plain @click="changeDoStatus(row,0)">启用</el-button>
            <el-button type="danger" v-if="row.doStatus===0" size="mini" plain @click="changeDoStatus(row,1)">停用</el-button>
            <!-- 审批按钮只有审批人能看 -->
            <!--                  <el-button type="warning"  v-if="row.auditUserId ==userInfo.id&&row.doStatus!=0&&row.doStatus!=1" size="mini" plain @click="handleAudit(row)">审批</el-button>-->
            <el-button type="warning"  v-if="row.auditUserId ==userInfo.id&&row.doStatus!=0&&row.doStatus!=1" size="mini" plain @click="auditHandClick(row)">审批</el-button>
            <el-button type="primary" size="mini" plain @click="showRecord(row)">查看记录</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flex-center" style="padding-top:10px;">
      <el-pagination
          v-if="dataTotal > 10"
          background
          :current-page.sync="SearchValue.pageCurrent"
          :page-size="SearchValue.pageSize"
          :total="dataTotal"
          layout="total, prev, pager, next"
          @current-change="handleSizeChangeData"
      ></el-pagination>
    </div>

    <select v-show="false" class="label-operate-item-value" id="select-printlist" ></select>
    <!-- 新增 -->
    <el-dialog
        title="新增吊篮"
        v-if="isShowAdd"
        :visible.sync="isShowAdd"
        @before-close="closeDialog"
        width="1100px">
      <el-form ref="elForm" :rules="rules" :inline="true" :model="formData" class="demo-form-inline" label-width="140px">
        <div class="titleborder">基本信息</div>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮位置" prop="location">
              <el-input v-model="formData.location" placeholder="请输入吊篮位置"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="检查通知人" prop="userIdList">
              <el-select v-model="formData.userIdList" multiple placeholder="请选择检查通知人">
                <el-option
                    v-for="item in notifier"
                    :key="item.id + 'ddd'"
                    :label="item.nickname"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮型号" prop="model">
              <el-input v-model="formData.model" placeholder="请输入吊篮型号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="额定荷载（KG）" prop="ratedLoad">
              <el-input v-model="formData.ratedLoad" placeholder="请输入额定荷载"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮类型" prop="hangingBasketType">
              <el-select v-model="formData.hangingBasketType" placeholder="请选择吊篮类型">
                <el-option
                    v-for="item in options2"
                    :key="item.value + 'bbb'"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="悬吊平台尺寸" prop="platformSize">
              <el-input v-model="formData.platformSize" placeholder="请输入悬吊平台尺寸"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="安装方式"  prop="installWay">
              <el-select v-model="formData.installWay" placeholder="请选择安装方式">
                <el-option
                    v-for="item in options1"
                    :key="item.value + 'ccc'"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="吊篮租赁单位" prop="vendor">
              <el-input v-model="formData.vendor" placeholder="请输入吊篮租赁单位"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="维修工名称" prop="siteHead">
              <el-input v-model="formData.siteHead" placeholder="请输入维修工名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="维修工电话" prop="siteHeadMobile">
              <el-input v-model="formData.siteHeadMobile" placeholder="请输入维修工电话"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="项目安全员" prop="safetyOfficerName" >
              <el-input v-model="formData.safetyOfficerName" disabled placeholder="请选择项目安全员" style="width: 225px !important;"></el-input>
              <el-button class="form-choose-button" @click="startChoose">选择</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="安全员电话" prop="safetyOfficerMobile" disabled>
              <el-input v-model="formData.safetyOfficerMobile" disabled placeholder="安全员电话"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-row>
              <el-form-item label="吊篮编码" prop="coding">
                <el-input v-model="formData.coding" placeholder="系统自动生成" disabled></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="吊篮安装单位" prop="installUnit">
                <el-input v-model="formData.installUnit" placeholder="吊篮安装单位"></el-input>
              </el-form-item>
            </el-row>
          </el-col>
          <el-col :span="11">
            <el-form-item label="安全检测合格证" required>
              <el-upload
                  :show-file-list="false"
                  :on-remove="handleRemove"
                  :on-success="handleSuccess"
                  class="editor-slide-upload"
                  :action="upLoadUrl"
                  list-type="picture-card"
              >
                <img v-if="formData.safetyCertificate" :src="formData.safetyCertificate" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="启用时间" prop="enabledTime">
              <el-date-picker
                  v-model="formData.enabledTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="启用时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="启用文件" prop="enabledFile">
              <upload-file style="width: 300px;" v-model="formData.enabledFile"></upload-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="五方验收时间" prop="fiveAcceptanceTime">
              <el-date-picker
                  v-model="formData.fiveAcceptanceTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="五方验收时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="五方验收文件" prop="fiveAcceptanceFile">
              <upload-file style="width: 300px;" v-model="formData.fiveAcceptanceFile"></upload-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="第三方检测时间" prop="thirdDetectionTime">
              <el-date-picker
                  v-model="formData.thirdDetectionTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="第三方检测时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="第三方检测文件" prop="thirdDetectionFile">
              <upload-file style="width: 300px;" v-model="formData.thirdDetectionFile"></upload-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮单位自检合格时间" prop="thirdReportAcquisitionTime">
              <el-date-picker
                  v-model="formData.thirdReportAcquisitionTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="吊篮单位自检合格时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="吊篮单位自检合格文件" prop="thirdReportAcquisitionFile">
              <upload-file style="width: 300px;" v-model="formData.thirdReportAcquisitionFile"></upload-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮进场时间" prop="approachTime">
              <el-date-picker
                  v-model="formData.approachTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="吊篮进场时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
          </el-col>
        </el-row>
        <div class="titleborder">属性</div>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="编制人">
              <el-input v-model="formData.makeName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="编制日期">
              <el-input v-model="formData.makeTime" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="单据编码">
              <el-input v-model="formData.documentsCoding" placeholder="系统自动生成" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="编制机构">
              <el-input v-model="departmentName" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm" size="small">确 定</el-button>
        <el-button type="warning" @click="submitDl" size="small">提 交</el-button>
        <el-button @click="isShowAdd = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 修改 -->
    <el-dialog
        title="修改吊篮"
        v-if="isShowEdit"
        style="overflow-x: hidden"
        :visible.sync="isShowEdit"
        width="1100px">
      <el-form :inline="true" ref="elForms" :rules="rules" :model="editForm" class="demo-form-inline" label-width="140px" >
        <div class="titleborder">基本信息</div>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮位置" prop="location">
              <el-input v-model="editForm.location" placeholder="请输入吊篮位置"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="检查通知人" prop="userIdList">
              <el-select v-model="editForm.userIdList" multiple placeholder="请选择检查通知人">
                <el-option
                    v-for="item in notifier"
                    :key="item.id + 'ddd'"
                    :label="item.nickname"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮型号" prop="model">
              <el-input v-model="editForm.model" placeholder="请输入吊篮型号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="额定荷载（KG）"  prop="ratedLoad">
              <el-input v-model="editForm.ratedLoad" placeholder="请输入额定荷载"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮类型"  prop="hangingBasketType">
              <el-select v-model="editForm.hangingBasketType" placeholder="请选择吊篮类型">
                <el-option
                    v-for="item in options2"
                    :key="item.value + 'bbb'"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="悬吊平台尺寸"  prop="platformSize">
              <el-input v-model="editForm.platformSize" placeholder="请输入悬吊平台尺寸"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="安装方式" prop="installWay">
              <el-select v-model="editForm.installWay" placeholder="请选择安装方式">
                <el-option
                    v-for="item in options1"
                    :key="item.value + 'ccc'"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="吊篮租赁单位" prop="vendor">
              <el-input v-model="editForm.vendor" placeholder="请输入吊篮租赁单位"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="维修工名称" prop="siteHead">
              <el-input v-model="editForm.siteHead" placeholder="请输入维修工名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="维修工电话" prop="siteHeadMobile">
              <el-input v-model="editForm.siteHeadMobile" placeholder="请输入维修工电话"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="项目安全员" prop="safetyOfficerName" >
              <el-input v-model="editForm.safetyOfficerName" disabled placeholder="请选择项目安全员" style="width: 225px !important;"></el-input>
              <el-button class="form-choose-button" @click="startChoose">选择</el-button>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="安全员电话" prop="safetyOfficerMobile" disabled>
              <el-input v-model="editForm.safetyOfficerMobile" disabled placeholder="安全员电话"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-row>
              <el-form-item label="吊篮编码" prop="coding">
                <el-input v-model="editForm.coding" placeholder="系统自动生成" disabled></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="吊篮安装单位" prop="installUnit">
                <el-input v-model="editForm.installUnit" placeholder="吊篮安装单位"></el-input>
              </el-form-item>
            </el-row>
          </el-col>
          <el-col :span="11">
            <el-form-item label="安全检测合格证" required>
              <el-upload
                  :multiple="true"
                  :file-list="fileList"
                  :show-file-list="false"
                  :on-remove="handleRemove"
                  :on-success="handleEditSuccess"
                  class="editor-slide-upload"
                  :action="upLoadUrl"
                  list-type="picture-card"
              >
                <img v-if="editForm.safetyCertificate" :src="editForm.safetyCertificate" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="启用时间" prop="enabledTime">
              <el-date-picker
                  v-model="editForm.enabledTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="启用时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="启用文件" prop="enabledFile">
              <upload-file style="width: 300px;" v-model="editForm.enabledFile"></upload-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="五方验收时间" prop="fiveAcceptanceTime">
              <el-date-picker
                  v-model="editForm.fiveAcceptanceTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="五方验收时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="五方验收文件" prop="fiveAcceptanceFile">
              <upload-file style="width: 300px;" v-model="editForm.fiveAcceptanceFile"></upload-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="第三方检测时间" prop="thirdDetectionTime">
              <el-date-picker
                  v-model="editForm.thirdDetectionTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="第三方检测时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="第三方检测文件" prop="thirdDetectionFile">
              <upload-file style="width: 300px;" v-model="editForm.thirdDetectionFile"></upload-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮单位自检合格时间" prop="thirdReportAcquisitionTime">
              <el-date-picker
                  v-model="editForm.thirdReportAcquisitionTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="吊篮单位自检合格时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="吊篮单位自检合格文件" prop="thirdReportAcquisitionFile">
              <upload-file style="width: 300px;" v-model="editForm.thirdReportAcquisitionFile"></upload-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮进场时间" prop="approachTime">
              <el-date-picker
                  v-model="editForm.approachTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="吊篮进场时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
          </el-col>
        </el-row>
        <div class="titleborder">属性</div>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="编制人">
              <el-input v-model="editForm.makeName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="编制日期">
              <el-input v-model="editForm.makeTime" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="单据编码">
              <el-input v-model="editForm.documentsCoding" placeholder="系统自动生成" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="编制机构">
              <el-input v-model="editForm.departmentName" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmEdit" size="small">确 定</el-button>
        <el-button v-if="editForm.hangingBasketStatus == 'one'" type="warning" @click="confirmEditSubmitDl" size="small">提 交</el-button>
        <el-button @click="isShowEdit = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 查看详情 -->
    <el-dialog
        title="吊篮详情"
        v-if="isShowDetails"
        :visible.sync="isShowDetails"
        width="1100px">
      <el-form :inline="true" :model="editForm" class="demo-form-inline" label-width="140px">
        <div class="titleborder">基本信息</div>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮位置">
              <el-input v-model="editForm.location" placeholder="请输入吊篮位置" disabled=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="检查通知人">
              <el-input v-model="editForm.userNameList" placeholder="请输入检查通知人" disabled></el-input>
              <!--              <el-select v-model="editForm.userIdList" multiple placeholder="请选择检查通知人" disabled>-->
              <!--                <el-option-->
              <!--                    v-for="item in notifier"-->
              <!--                    :key="item.id + 'ddd'"-->
              <!--                    :label="item.nickname"-->
              <!--                    :value="Number(item.id)">-->
              <!--                </el-option>-->
              <!--              </el-select>-->
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮型号">
              <el-input v-model="editForm.model" placeholder="请输入吊篮型号" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="额定荷载（KG）">
              <el-input v-model="editForm.ratedLoad" placeholder="请输入额定荷载" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮类型">
              <el-select v-model="editForm.hangingBasketType" placeholder="请选择吊篮类型" disabled>
                <el-option
                    v-for="item in options2"
                    :key="item.value + 'bbb'"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="悬吊平台尺寸">
              <el-input v-model="editForm.platformSize" placeholder="请输入悬吊平台尺寸" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="安装方式">
              <el-select v-model="editForm.installWay" placeholder="请选择安装方式" disabled>
                <el-option
                    v-for="item in options1"
                    :key="item.value + 'ccc'"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="吊篮租赁单位">
              <el-input v-model="editForm.vendor" placeholder="请输入吊篮租赁单位" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="维修工名称">
              <el-input v-model="editForm.siteHead" placeholder="请输入维修工名称" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="维修工电话" prop="siteHeadMobile">
              <el-input v-model="editForm.siteHeadMobile" placeholder="请输入维修工电话" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="项目安全员" prop="safetyOfficerName" >
              <el-input v-model="editForm.safetyOfficerName" disabled placeholder="请选择项目安全员"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="安全员电话" prop="safetyOfficerMobile" disabled>
              <el-input v-model="editForm.safetyOfficerMobile" disabled placeholder="安全员电话"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-row>
              <el-form-item label="吊篮编码" prop="coding">
                <el-input v-model="editForm.coding" placeholder="系统自动生成" disabled></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="吊篮安装单位" prop="installUnit">
                <el-input v-model="editForm.installUnit" placeholder="吊篮安装单位" disabled></el-input>
              </el-form-item>
            </el-row>
          </el-col>
          <el-col :span="11">
            <el-form-item label="安全检测合格证" required>
              <img v-if="editForm.safetyCertificate" :src="editForm.safetyCertificate" class="el-upload--picture-card">
              <div v-else class="flex-center el-upload--picture-card">暂无图片</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="启用时间" prop="enabledTime">
              <el-date-picker
                  v-model="editForm.enabledTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="启用时间"
                  disabled
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="启用文件" prop="enabledFile">
              <upload-file style="width: 300px;" v-model="editForm.enabledFile" isView></upload-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="五方验收时间" prop="fiveAcceptanceTime">
              <el-date-picker
                  v-model="editForm.fiveAcceptanceTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="五方验收时间"
                  disabled
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="五方验收文件" prop="fiveAcceptanceFile">
              <upload-file style="width: 300px;" v-model="editForm.fiveAcceptanceFile" isView></upload-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="第三方检测时间" prop="thirdDetectionTime">
              <el-date-picker
                  v-model="editForm.thirdDetectionTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="第三方检测时间"
                  disabled
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="第三方检测文件" prop="thirdDetectionFile">
              <upload-file style="width: 300px;" v-model="editForm.thirdDetectionFile" isView></upload-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮单位自检合格时间" prop="thirdReportAcquisitionTime">
              <el-date-picker
                  v-model="editForm.thirdReportAcquisitionTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="吊篮单位自检合格时间"
                  disabled
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="吊篮单位自检合格文件" prop="thirdReportAcquisitionFile">
              <upload-file style="width: 300px;" v-model="editForm.thirdReportAcquisitionFile" isView></upload-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮进场时间" prop="approachTime">
              <el-date-picker
                  v-model="editForm.approachTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="吊篮进场时间"
                  disabled
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
          </el-col>
        </el-row>
        <div class="titleborder">属性</div>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="编制人">
              <el-input v-model="editForm.makeName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="编制日期">
              <el-input v-model="editForm.makeTime" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="单据编码">
              <el-input v-model="editForm.documentsCoding" placeholder="系统自动生成" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="编制机构">
              <el-input v-model="editForm.departmentName" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="isShowDetails = false" size="small">确 定</el-button>
        <el-button @click="isShowDetails = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 审批查看详情 -->
    <el-dialog
        title="审批吊篮详情"
        v-if="isShowAuditDetails"
        :visible.sync="isShowAuditDetails"
        width="60%">
      <el-form :inline="true" :model="editForm" class="demo-form-inline" label-width="140px">
        <div class="titleborder">基本信息</div>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮位置">
              <el-input v-model="editForm.location" placeholder="请输入吊篮位置" disabled=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="检查通知人">
              <el-input v-model="editForm.userNameList" placeholder="请输入检查通知人" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮型号">
              <el-input v-model="editForm.model" placeholder="请输入吊篮型号" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="额定荷载（KG）">
              <el-input v-model="editForm.ratedLoad" placeholder="请输入额定荷载" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮类型">
              <el-select v-model="editForm.hangingBasketType" placeholder="请选择吊篮类型" disabled>
                <el-option
                    v-for="item in options2"
                    :key="item.value + 'bbb'"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="悬吊平台尺寸">
              <el-input v-model="editForm.platformSize" placeholder="请输入悬吊平台尺寸" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="安装方式">
              <el-select v-model="editForm.installWay" placeholder="请选择安装方式" disabled>
                <el-option
                    v-for="item in options1"
                    :key="item.value + 'ccc'"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="吊篮租赁单位">
              <el-input v-model="editForm.vendor" placeholder="请输入吊篮租赁单位" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="维修工名称">
              <el-input v-model="editForm.siteHead" placeholder="请输入维修工名称" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="维修工电话" prop="siteHeadMobile">
              <el-input v-model="editForm.siteHeadMobile" placeholder="请输入维修工电话" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="项目安全员" prop="safetyOfficerName" >
              <el-input v-model="editForm.safetyOfficerName" disabled placeholder="请选择项目安全员"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="安全员电话" prop="safetyOfficerMobile" disabled>
              <el-input v-model="editForm.safetyOfficerMobile" disabled placeholder="安全员电话"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮编码">
              <el-input v-model="editForm.coding" placeholder="系统自动生成" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="安全检测合格证">
              <img v-if="editForm.safetyCertificate" :src="editForm.safetyCertificate" class="el-upload--picture-card">
              <div v-else class="flex-center el-upload--picture-card">暂无图片</div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="启用时间" prop="enabledTime">
              <el-date-picker
                  v-model="editForm.enabledTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="启用时间"
                  disabled
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="启用文件" prop="enabledFile">
              <upload-file style="width: 300px;" v-model="editForm.enabledFile" isView></upload-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="五方验收时间" prop="fiveAcceptanceTime">
              <el-date-picker
                  v-model="editForm.fiveAcceptanceTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="五方验收时间"
                  disabled
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="五方验收文件" prop="fiveAcceptanceFile">
              <upload-file style="width: 300px;" v-model="editForm.fiveAcceptanceFile" isView></upload-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="第三方检测时间" prop="thirdDetectionTime">
              <el-date-picker
                  v-model="editForm.thirdDetectionTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="第三方检测时间"
                  disabled
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="第三方检测文件" prop="thirdDetectionFile">
              <upload-file style="width: 300px;" v-model="editForm.thirdDetectionFile" isView></upload-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮单位自检合格时间" prop="thirdReportAcquisitionTime">
              <el-date-picker
                  v-model="editForm.thirdReportAcquisitionTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="吊篮单位自检合格时间"
                  disabled
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="吊篮单位自检合格文件" prop="thirdReportAcquisitionFile">
              <upload-file style="width: 300px;" v-model="editForm.thirdReportAcquisitionFile" isView></upload-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮进场时间" prop="approachTime">
              <el-date-picker
                  v-model="editForm.approachTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="吊篮进场时间"
                  disabled
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
          </el-col>
        </el-row>
        <div class="titleborder">属性</div>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="编制人">
              <el-input v-model="editForm.makeName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="编制日期">
              <el-input v-model="editForm.makeTime" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="单据编码">
              <el-input v-model="editForm.documentsCoding" placeholder="系统自动生成" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="编制机构">
              <el-input v-model="editForm.departmentName" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="双方协调确认单">
              <div style="width: 220px;text-align: left;">
                <el-link type="primary" :href="editForm.confirmationSheet" target="_blank">双方协调确认单</el-link>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="AuditConfirm" size="small">确 认</el-button>
        <el-button type="primary" @click="AuditRefused" size="small">打 回</el-button>
        <el-button @click="isShowAuditDetails = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 修改检查通知人 -->
    <el-dialog
        title="修改检查通知人"
        v-if="changeNotifier"
        :visible.sync="changeNotifier"
        width="30%">
      <el-form :inline="true" :model="notifierForm" class="demo-form-inline" label-width="120px">
        <div class="titleborder">基本信息</div>
        <el-form-item label="检查通知人">
          <el-select v-model="notifierForm.userIdList" multiple placeholder="请选择检查通知人">
            <el-option
                v-for="item in notifier"
                :key="item.id + 'ddd'"
                :label="item.nickname"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmNotifier" size="small">确 定</el-button>
        <el-button @click="changeNotifier = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 打印二维码 -->
    <el-dialog
        title="打印二维码"
        v-if="isShowPrint"
        :visible.sync="isShowPrint"
        width="30%">
      <div class="flex-column" ref="imageWrapper" id="imageWrapper" style="width:100%;min-height:730px;background:#f2f2f2;padding-top:40px">
        <div class="qrcode-pic flex-center" ref="codeItem"></div>
        <div  class="flex-center title titles" style="font-size: 32px;">{{parentmsg.departmentName}}</div>
        <div style="display: flex;flex-direction: column">
          <div class="flex-center"  style="justify-content:space-around;margin-top:20px;">
            <div class="title" style="font-size: 32px;">{{parentmsg.location}}({{parentmsg.type}})</div>
            <div class="title" style="font-size: 32px;">{{parentmsg.coding}}</div>
          </div>
          <div class="flex-center"  style="justify-content:space-around;margin-top:10px;">
            <div class="title flex-column" style="font-size: 32px;">
              <div>维修工</div>
              <div>{{parentmsg.siteHead}}</div>
            </div>
            <div class="title flex-column" style="font-size: 32px;">
              <div>维修工电话</div>
              <div>{{parentmsg.siteHeadMobile}}</div>
            </div>
          </div>
          <div class="flex-center"  style="justify-content:space-around;margin-top:10px;">
            <div class="title flex-column" style="font-size: 32px;">
              <div> 安全员</div>
              <div>
                {{parentmsg.safetyOfficerName}}
              </div>
            </div>
            <div class="title flex-column" style="font-size: 32px;">
              <div> 安全员电话</div>
              <div>
                {{parentmsg.safetyOfficerMobile}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button type="primary" v-print="'#printTest'" size="small">确 定</el-button> -->
        <el-button type="primary" size="small" @click="changeType">切换方向</el-button>
        <el-button type="primary" size="small" @click="toImage">打 印</el-button>
        <el-button @click="isShowPrint = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 选择项目安全员 -->
    <el-dialog
        ref="chooseRefs"
        title="选择项目安全员"
        v-if="isShowChooseSafe"
        :visible.sync="isShowChooseSafe"
        width="40%">
      <div class="content flex-column">
        <!--        <div class="flex">-->
        <!--          <div class="operation flex-center-v">-->
        <!--            <el-input v-model="safeSearchValue.name" placeholder="请输入安全员名称" size="small" style="width:140px;margin-right:15px;"></el-input>-->
        <!--          </div>-->
        <!--          <div class="search">-->
        <!--            <el-button type="primary" size="small" style="width:80px;" @click="search">查询</el-button>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="cont">
          <el-table
              :data="safeList"
              :header-cell-style="{background:'#f5f5f5',color:'#333333'}"
          >
            <el-table-column align="center" prop="nickname" label="项目安全员"></el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" plain @click="chooseSafe(row)">选择</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>
    <!-- 选择审批人 -->
    <el-dialog
        ref="chooseRefs"
        title="选择审批人"
        v-if="isShowChooseDoPeople"
        :visible.sync="isShowChooseDoPeople"
        @close="closeChooseDoPeople"
        width="30%">
      <div class="content">
        <el-form label-width="80px">
          <el-form-item label="审批人">
            <el-select v-model="changeDoStatusForm.auditUserId" placeholder="请选择检审批人" @change="chooseAuditUser">
              <el-option
                  v-for="item in checkList"
                  :key="item.id + 'ddd'"
                  :label="item.nickname"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="双方协调确认单" class="flex-start">
            <el-upload
                :file-list="checkBothFilelist"
                :show-file-list="true"
                :on-remove="handleRemoveConfirm"
                :on-success="handleSuccessConfirm"
                :action="upLoadUrl"
                class="upload-demo"
                :on-preview="handlePreview"
            >
              <el-button type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
        <span class="flex-end">
          <el-button type="primary" @click="confirmChangeDoStatus" size="small">确 定</el-button>
          <el-button @click="closeChooseDoPeople" size="small">取 消</el-button>
        </span>
      </div>
    </el-dialog>
    <!-- 查看报停记录 -->
    <el-dialog
        ref="chooseRefs"
        title="报停记录"
        v-if="isShowDoStatusRecord"
        :visible.sync="isShowDoStatusRecord"
        width="55%">
      <div class="content flex-column">
        <div class="cont">
          <el-table :data="doStatusRecordList" :header-cell-style="{background:'#f5f5f5',color:'#333333'}">
            <el-table-column align="center" prop="commitUserName" label="提交人姓名"></el-table-column>
            <el-table-column align="center" prop="commitTime" label="提交时间"></el-table-column>
            <el-table-column align="center" prop="auditUserName" label="审核人姓名"></el-table-column>
            <el-table-column align="center" prop="auditTime" label="审核时间"></el-table-column>
            <el-table-column align="center" prop="confirmationSheet" label="双方协调确认单">
              <template slot-scope="{ row }">
                <el-link type="primary" :href="row.confirmationSheet" target="_blank" v-if="row.confirmationSheet">双方协调确认单</el-link>
                <div v-else>暂无链接</div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="ifConfirm" label="状态">
              <template slot-scope="{ row }">
                <div>{{ row.ifConfirm==1 ? '确认' : row.ifConfirm==2 ? '打回' : '未确认' }}</div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flex-center" style="padding-top:10px;">
            <el-pagination
                background
                :current-page.sync="searchForm.pageCurrent"
                :page-size="searchForm.pageSize"
                :total="doStatusRecordTotal"
                layout="total, prev, pager, next"
                @current-change="handleSizeChangeReocrdData"
            ></el-pagination>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 修改一台一册 -->
    <el-dialog
        title="修改一台一册"
        v-if="isShowEditOne"
        style="overflow-x: hidden"
        :visible.sync="isShowEditOne"
        width="60%">
      <el-form :inline="true" ref="oneFormData" :rules="rulesOne" :model="oneFormData" class="demo-form-inline" label-width="160px" >
        <div class="titleborder">基本信息</div>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="启用时间" prop="enabledTime">
              <el-date-picker
                  v-model="oneFormData.enabledTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="启用时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="启用文件" prop="enabledFile">
              <upload-file style="width: 350px;" v-model="oneFormData.enabledFile"></upload-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="五方验收时间" prop="fiveAcceptanceTime">
              <el-date-picker
                  v-model="oneFormData.fiveAcceptanceTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="五方验收时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="五方验收文件" prop="fiveAcceptanceFile">
              <upload-file style="width: 350px;" v-model="oneFormData.fiveAcceptanceFile"></upload-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="第三方检测时间" prop="thirdDetectionTime">
              <el-date-picker
                  v-model="oneFormData.thirdDetectionTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="第三方检测时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="第三方检测文件" prop="thirdDetectionFile">
              <upload-file style="width: 350px;" v-model="oneFormData.thirdDetectionFile"></upload-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮单位自检合格时间" prop="thirdReportAcquisitionTime">
              <el-date-picker
                  v-model="oneFormData.thirdReportAcquisitionTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="吊篮单位自检合格时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="吊篮单位自检合格文件" prop="thirdReportAcquisitionFile">
              <upload-file style="width: 350px;" v-model="oneFormData.thirdReportAcquisitionFile"></upload-file>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮进场时间" prop="approachTime">
              <el-date-picker
                  v-model="oneFormData.approachTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="吊篮进场时间"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmEditOne" size="small">确 定</el-button>
        <el-button @click="isShowEditOne = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import '../api/web'
/* eslint-disable */
var api = new LPAPIWeb();
import html2canvas from 'html2canvas';
import { mapGetters } from 'vuex'
import apis from '../api/nacelle-set.js'
import QRCode from 'qrcodejs2'
import BaseURL from "@/apis/baseurl";
import {accessToken} from "@/utils/local-cache";
import UploadFile from "../components/upload-file";
export default {
  name: "one",

  components: {
    UploadFile
  },

  props: {},

  data() {
    return {
      dataTotal: 0,
      searchName: '',
      tableData: [],
      safeList:[],
      fileList: [],
      multipleSelection: [],
      doStatusRecordList:[],//启用报停记录
      safeSearchValue:{
        name:'',
        pageCurrent: 1,
        pageSize: 10,
        dataTotal:0
      },
      selectItems:{},//暂存选中停用时候的数据
      SearchValue: {
        pageCurrent: 1,
        pageSize: 10,
        location: '',
        model: '',
        hangingBasketType: '',
        departmentCode: '',
      },
      options1: [
        { value: 'one', label: '标准'},
        { value: 'two', label: '非标1'},
        { value: 'three', label: '非标2'},
        { value: 'four', label: '非标3'}
      ],
      options2: [
        { value: 'one', label: '高空吊篮'},
        { value: 'two', label: '钢制吊篮'},
        { value: 'three', label: '弧形吊篮'},
        { value: 'four', label: '直角吊篮'},
        { value: 'five', label: '铝制吊篮'},
        { value: 'sex', label: '单人吊篮'},
        { value: 'seven', label: '高处作业吊篮'},
      ],
      rules:{
        location: [{ required: true, message: '请输入吊篮位置', trigger: 'blur' }],
        userIdList: [{ required: true, message: '请选择检查通知人', trigger: 'change' }],
        model: [{ required: true, message: '请输入吊篮型号', trigger: 'blur' }],
        ratedLoad: [{ required: true, message: '请输入额定荷载', trigger: 'blur' }],
        hangingBasketType: [{ required: true, message: '请选择吊篮类型', trigger: 'change' }],
        platformSize: [{ required: true, message: '请输入悬吊平台尺寸', trigger: 'blur' }],
        installWay: [{ required: true, message: '请选择安装方式', trigger: 'change' }],
        vendor: [{ required: true, message: '请输入吊篮租赁单位', trigger: 'blur' }],
        siteHeadMobile: [{ required: true, message: '请输入维修工电话', trigger: 'blur' }],
        siteHead: [{ required: true, message: '请输入维修工名称', trigger: 'blur' }],
        safetyOfficerMobile: [{ required: true, message: '请输入项目安全员姓名', trigger: 'blur' }],
        safetyOfficerName: [{ required: true, message: '请输入项目安全员电话', trigger: 'blur' }],
        installUnit: [{ required: true, message: '请输入吊篮安装单位', trigger: 'blur' }],
        enabledTime: [{ required: false, message: '请输入启用时间', trigger: 'blur' }],
        enabledFile: [{ required: false, message: '请选择启用文件', trigger:[ 'blur', 'change'] }],
        fiveAcceptanceTime: [{ required: false, message: '请输入五方验收时间', trigger: 'blur' }],
        fiveAcceptanceFile: [{ required: false, message: '请选择五方验收文件', trigger:[ 'blur', 'change'] }],
        thirdDetectionTime: [{ required: false, message: '请输入第三方检测时间', trigger: 'blur' }],
        thirdDetectionFile: [{ required: false, message: '请选择第三方检测文件', trigger:[ 'blur', 'change'] }],
        thirdReportAcquisitionTime: [{ required: false, message: '请输入吊篮单位自检合格时间', trigger: 'blur' }],
        thirdReportAcquisitionFile: [{ required: false, message: '请选择吊篮单位自检合格文件', trigger:[ 'blur', 'change'] }],
        approachTime: [{ required: false, message: '请输入吊篮进场时间', trigger: 'blur' }],
      },
      notifier: [],
      isShowAdd: false,
      departmentName: '',
      formData: {
        installUnit: '',
        enabledTime: '',
        enabledFile: '',
        fiveAcceptanceTime: '',
        fiveAcceptanceFile: '',
        thirdDetectionTime: '',
        thirdDetectionFile: '',
        thirdReportAcquisitionTime: '',
        thirdReportAcquisitionFile: '',
        approachTime: '',
        // 以上为新增字段
        departmentCode: '',
        userIdList: [],
        location: '',
        model: '',
        safetyCertificate:'',
        hangingBasketType: '',
        ratedLoad: '',
        platformSize: '',
        installWay: '',
        vendor: '',
        siteHead: '',
        siteHeadMobile: '',
        safetyOfficerName:'',
        safetyOfficerMobile:'',
        makeTime: '',
        coding: '', // 吊篮编码
        documentsCoding: '', // 单据编码
        makeName: '',
        id: ''
      },
      editForm: {},
      imgUrl:{},
      isShowEdit: false,
      changeNotifier: false,
      checkList:[],//选择审批人列表
      notifierForm: {
        userIdList: [],
        id: ''
      },
      searchForm:{
        id:''
      },
      doStatusRecordTotal:0,
      changeDoStatusForm:{
        confirmationSheet:'',
        auditUserName:'',
        auditUserId:'',
        status:''
      },//选择审批人之后改变状态的表单
      isShowPrint: false,
      isShowDetails: false,
      parentmsg:{
        type:'上'
      },
      isShowChooseSafe:false,//是否选择项目安全员
      isShowChooseDoPeople:false,//是否选择审批人
      isShowDoStatusRecord:false,
      checkBothFilelist:[],
      isShowAuditDetails: false,
      isShowEditOne: false,
      oneFormData: {
        enabledTime: '',
        enabledFile: '',
        fiveAcceptanceTime: '',
        fiveAcceptanceFile: '',
        thirdDetectionTime: '',
        thirdDetectionFile: '',
        thirdReportAcquisitionTime: '',
        thirdReportAcquisitionFile: '',
        approachTime: '',
      },
      rulesOne: {
        enabledTime: [{ required: false, message: '请输入启用时间', trigger: 'blur' }],
        enabledFile: [{ required: false, message: '请选择启用文件', trigger:[ 'blur', 'change'] }],
        fiveAcceptanceTime: [{ required: false, message: '请输入五方验收时间', trigger: 'blur' }],
        fiveAcceptanceFile: [{ required: false, message: '请选择五方验收文件', trigger:[ 'blur', 'change'] }],
        thirdDetectionTime: [{ required: false, message: '请输入第三方检测时间', trigger: 'blur' }],
        thirdDetectionFile: [{ required: false, message: '请选择第三方检测文件', trigger:[ 'blur', 'change'] }],
        thirdReportAcquisitionTime: [{ required: false, message: '请输入吊篮单位自检合格时间', trigger: 'blur' }],
        thirdReportAcquisitionFile: [{ required: false, message: '请选择吊篮单位自检合格文件', trigger:[ 'blur', 'change'] }],
        approachTime: [{ required: false, message: '请输入吊篮进场时间', trigger: 'blur' }],
      }
    }
  },

  computed: {
    ...mapGetters([ 'companyId', 'optionItem', 'userInfo']),
    upLoadUrl() {
      return BaseURL + '/admin/public/uploadFile'
    }
  },

  watch: {

    companyId() {
      this.init()
      this.getNotifY()
    },
    isShowAdd(old,val) {
      this.formData = {
        installUnit: '',
        enabledTime: '',
        enabledFile: '',
        fiveAcceptanceTime: '',
        fiveAcceptanceFile: '',
        thirdDetectionTime: '',
        thirdDetectionFile: '',
        thirdReportAcquisitionTime: '',
        thirdReportAcquisitionFile: '',
        approachTime: '',
        // 以上为新增字段
        departmentCode: '',
        userIdList: [],
        location: '',
        model: '',
        safetyCertificate:'',
        hangingBasketType: '',
        ratedLoad: '',
        platformSize: '',
        installWay: '',
        vendor: '',
        siteHead: '',
        siteHeadMobile: '',
        safetyOfficerName:'',
        safetyOfficerMobile:'',
        makeTime: '',
        coding: '', // 吊篮编码
        documentsCoding: '', // 单据编码
        makeName: '',
        id: ''
      },
          this.formData.safetyOfficerMobile=''
      this.formData.safetyOfficerName=''
      this.formData.safetyCertificate=''
      this.formData.makeTime = this.getNowFormatDate()
      this.formData.makeName = this.userInfo.nickname
      this.formData.departmentCode = this.companyId
    },
    // this.$set(this.formData,'safetyOfficerMobile','')
  },

  mounted() {
    setTimeout(() => {
      this.init()
      this.updatePrinterList();
    }, 500)
  },

  methods: {
    showRecord(row){
      this.isShowDoStatusRecord = true
      this.searchForm = {
        pageCurrent:1,
        pageSize:10
      }
      this.searchForm.hangingBasketId = row.id
      this.initRecord()
    },
    async initRecord(){
      apis.getDoStatusRecord(this.searchForm).then(res=>{
        this.doStatusRecordList = res.list
        this.doStatusRecordTotal = res.total
      })
    },
    closeChooseDoPeople(){
      this.changeDoStatusForm={}
      this.checkBothFilelist=[]
      this.isShowChooseDoPeople =false
    },
    changeType(){
      if (this.parentmsg.type==='上'){
        this.$nextTick(()=>{
          this.parentmsg.type='下'
        })
        this.$set(this.parentmsg,'type','下')
      }else {
        this.parentmsg.type='上'
      }
      this.parentmsg=JSON.parse(JSON.stringify(this.parentmsg))
      this.$nextTick(() => {
        this.$refs.codeItem.innerHTML = ''
        const params = this.parentmsg.id+','+this.parentmsg.type
        new QRCode(this.$refs.codeItem, {
          text: String(params), //二维码内容
          width: 350,
          height: 350,
          // colorDark: "#333333", //二维码颜色
          // colorLight: "#ffffff", //二维码背景色
        })
      }, 500)
    },
    toImage() {
      html2canvas(this.$refs.imageWrapper).then(canvas => {
        let dataURL = canvas.toDataURL("image/png");
        this.imgUrl = dataURL;
        // console.log(this.imgUrl)
        const files = this.base64ImgtoFile(this.imgUrl)
        const formData = new FormData()
        formData.append('file',files)
        this.file2link(formData)
      });
    },
    async file2link(formData) {
      const data = await apis.uploadFile(formData)
      this.printImage(data)
      this.isShowPrint = false
    },
    chooseAuditUser(e){
      const choosenItem = this.checkList.find((res)=>{return res.id == e})
      this.changeDoStatusForm.auditUserName = choosenItem.nickname
      this.changeDoStatusForm.auditUserId = e
    },
    async startChoose(){
      let params = this.safeSearchValue
      params.departmentCode= this.companyId
      this.safeList = this.notifier
      this.isShowChooseSafe = true
    },
    chooseSafe(row){
      this.formData.safetyOfficerName = row.nickname
      this.formData.safetyOfficerMobile = row.username
      this.editForm.safetyOfficerName = row.nickname
      this.editForm.safetyOfficerMobile = row.username
      this.isShowChooseSafe = false
      this.$refs.chooseRefs.close()
    },
    handleSuccess(response, file) {
      this.formData.safetyCertificate = response.data
    },
    handleSuccessConfirm(response) {
      this.changeDoStatusForm.confirmationSheet = response.data
      this.checkBothFilelist= [{name:'双方协调确认单',url:this.changeDoStatusForm.confirmationSheet}]
    },
    handleEditSuccess(response, file) {
      this.editForm.safetyCertificate = response.data
    },
    handleRemoveConfirm(){
      this.changeDoStatusForm.confirmationSheet = ''
    },
    handleRemove(){
      this.formData.safetyCertificate = ''
    },
    handlePreview(file) {
      console.log(file);
    },
    async changeDoStatus(row,status){
      this.selectItems = row
      this.checkList = await apis.findCheckPerson()
      this.isShowChooseDoPeople = true
      this.changeDoStatusForm.status = status
    },
    base64ImgtoFile(dataurl, filename = 'file') {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const suffix = mime.split('/')[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      })
    },
    /**
     * 更新打印机列表。
     */
    updatePrinterList() {
      document.createElement
      var printerElements = document.getElementById("select-printlist");
      var printers = api.getPrinters();
      if (printers instanceof Array && printers.length > 0) {
        for (var i = 0; i < printers.length; i++) {
          var item = printers[i];
          // 如果检测到局域网内的其他打印机，则可以获取ip和hostname，如果是本地打印机，则参数中只有name属性，表示打印机名称；
          var name = item.hostname ? item.name + "@" + item.hostname : item.name;
          var value = item.ip ? item.name + "@" + item.ip : item.name;
          printerElements.options.add(new Option(name, value));
        }
      } else {
        printerElements.options.add(new Option("未检测到打印机", ""));
      }
      // 链接默认打印机，并更新选中打印机打印参数；
      api.closePrinter();
    },
    printImage(url) {
      // 打开打印机
      if (!this.openPrinter())
        return;

      var width = 49;
      var height = 70;
      var url = url;
      var margin = 5;
      if (!api.startJob({width : width, height : height}))
        return false;

      api.drawImage({
        imageFile: url,
        x: margin,
        y: margin,
        width: width - margin * 2,
        height: height - margin * 2
      });
      api.commitJob();
      // 关闭打印机；
      api.closePrinter();
    },
    /**
     * 打开当前打印机；
     */
    openPrinter() {
      var printerElement = document.getElementById("select-printlist");
      if (!printerElement.value)
        return false;
      var arr = printerElement.value.split('@');
      var name = arr[0];
      var ip = arr[1];
      return api.openPrinter({ip: '127.0.0.1', printerName: name});
    },
    closeDialog(){
      console.log('函数触发',11111)
    },
    async init() {
      this.SearchValue.departmentCode = this.companyId
      const data = await apis.findAllDl(this.SearchValue)
      // console.log(data)
      this.tableData = data.list
      this.dataTotal = data.total
    },
    // 切换页码
    async handleSizeChangeData(val) {
      // console.warn(val)
      this.SearchValue.pageCurrent = val
      this.init()
    },
    // 切换记录页码
    async handleSizeChangeReocrdData(val) {
      // console.warn(val)
      this.searchForm.pageCurrent = val
      this.initRecord()
    },
    // 选中
    selectItem(rows) {
      if (rows.length > 1) {
        var newRows = rows.filter((it, index) => {
          if (index == rows.length - 1) {
            this.$refs.multipleTable.toggleRowSelection(it, true);
            return true;
          } else {
            this.$refs.multipleTable.toggleRowSelection(it, false);
            return false;
          }
        })
        this.multipleSelection = newRows;
      } else {
        this.multipleSelection = rows;
      }
    },
    search() {
      this.SearchValue.pageCurrent = 1
      this.init()
    },
    async getNotifY(id){
      if (id){
        this.notifier = await apis.findByDepartmentId(id)
      }else{
        this.notifier = await apis.findByDepartmentId(this.companyId)
      }
    },
    async add() { // 新增事件
      const isOperate = this.optionItem.permissionNameList.includes('hanging_basket_operate')
      if (!isOperate) return this.$message.error('请联系管理员添加操作权限')
      this.formData.departmentCode = this.companyId
      this.departmentName = this.optionItem.departmentName
      this.formData.makeTime = this.getNowFormatDate()
      this.formData.makeName = this.userInfo.nickname
      this.getNotifY()
      this.isShowAdd = true
    },
    confirm() { // 新增确定
      this.$refs['elForm'].validate( async (valid) => {
        if (!valid) return
        if (this.formData.safetyCertificate=='')return this.$message.error('安全检测合格证不能为空')
        await apis.addDl(this.formData)
        this.$message.success('操作成功')
        this.isShowAdd = false
        this.init()
      })
      // TODO 提交表单

    },
    submitDl() { // 提交事件
      this.$refs['elForm'].validate( async(valid) => {
        if (!valid) return
        if (this.formData.safetyCertificate=='')return this.$message.error('安全检测合格证不能为空')
        await apis.submitDl(this.formData)
        this.$message.success('操作成功')
        this.isShowAdd = false
        this.init()
      })

    },
    async copyAdd() { // 复制新增
      const isOperate = this.optionItem.permissionNameList.includes('hanging_basket_operate')
      if (!isOperate) return this.$message.error('请联系管理员添加操作权限')
      if (this.multipleSelection.length == 0) return this.$message.warning('请选择一个吊篮项')
      const data = this.multipleSelection[0]
      await apis.findByBasketId({id:data.id}).then(async res=>{
        const formData = {
          departmentCode: res.departmentCode,
          userIdList: res.userIdList,
          location: res.location,
          model: res.model,
          hangingBasketType: res.hangingBasketType,
          ratedLoad: res.ratedLoad,
          platformSize: res.platformSize,
          installWay: res.installWay,
          vendor: res.vendor,
          siteHead: res.siteHead,
          makeName: res.makeName,
          installUnit: res.installUnit,
          siteHeadMobile: res.siteHeadMobile,
          safetyOfficerMobile: res.safetyOfficerMobile,
          safetyOfficerName: res.safetyOfficerName,
          safetyCertificate: res.safetyCertificate,
          enabledTime: res.enabledTime,
          enabledFile: res.enabledFile,
          fiveAcceptanceTime: res.fiveAcceptanceTime,
          fiveAcceptanceFile: res.fiveAcceptanceFile,
          thirdDetectionTime: res.thirdDetectionTime,
          thirdDetectionFile: res.thirdDetectionFile,
          thirdReportAcquisitionTime: res.thirdReportAcquisitionTime,
          thirdReportAcquisitionFile: res.thirdReportAcquisitionFile,
          approachTime: res.approachTime,
        }
        formData.makeTime = this.getNowFormatDate()
        this.$confirm('是否复制新增吊篮', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const data = await apis.addDl(formData)
          this.getNotifY(formData.departmentCode)
          await apis.findByBasketId({ id:data }).then(async res=>{
            this.editForm=res
          })
          this.isShowEdit = true
          this.init()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      })
    },
    // 修改一台一侧
    async editOne() {
      const isOperate = this.optionItem.permissionNameList.includes('hanging_basket_operate')
      if (!isOperate) return this.$message.error('请联系管理员添加操作权限')
      if (this.multipleSelection.length == 0) return this.$message.warning('请选择一个吊篮项')
      this.getNotifY(this.multipleSelection[0].departmentCode)
      const data = this.multipleSelection[0]
      // console.log(data);
      await apis.findByBasketId({id:data.id}).then(async res=>{
        this.oneFormData = res
      })
      this.isShowEditOne = true
    },
    // 一台一侧确定
    confirmEditOne() {
      this.$refs.oneFormData.validate( async(valid) => {
        if (!valid) return
        await apis.updateYtTc(this.oneFormData)
        this.$message.success('操作成功')
        this.$refs.oneFormData.resetFields()
        this.isShowEditOne = false
        this.init()
      })
    },
    async edit() { // 修改
      const isOperate = this.optionItem.permissionNameList.includes('hanging_basket_operate')
      if (!isOperate) return this.$message.error('请联系管理员添加操作权限')
      if (this.multipleSelection.length == 0) return this.$message.warning('请选择一个吊篮项')
      this.getNotifY(this.multipleSelection[0].departmentCode)
      const data = this.multipleSelection[0]
      // console.log(data);
      await apis.findByBasketId({id:data.id}).then(async res=>{
        this.editForm = res
      })
      this.isShowEdit = true
    },
    confirmEdit() { // 修改确定
      this.$refs['elForms'].validate( async(valid) => {
        if (!valid) return
        await apis.updateDl(this.editForm)
        this.$message.success('操作成功')
        this.isShowEdit = false
        this.init()
      })
    },
    // 修改提交
    confirmEditSubmitDl(){
      this.$refs['elForms'].validate( async(valid) => {
        if (!valid) return
        await apis.submitDl(this.editForm)
        this.$message.success('操作成功')
        this.isShowEdit = false
        this.init()
      })
    },
    dismantle() { // 拆除
      const isOperate = this.optionItem.permissionNameList.includes('hanging_basket_operate')
      if (!isOperate) return this.$message.error('请联系管理员添加操作权限')
      if (this.multipleSelection.length == 0) return this.$message.warning('请选择一个吊篮项')
      const data = this.multipleSelection[0]
      this.$confirm('是否拆除吊篮', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await apis.removeDl(data.id)
        this.$message.success('操作成功')
        this.init()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    detele() { // 删除
      const isOperate = this.optionItem.permissionNameList.includes('hanging_basket_operate')
      if (!isOperate) return this.$message.error('请联系管理员添加操作权限')
      if (this.multipleSelection.length == 0) return this.$message.warning('请选择一个吊篮项')
      const data = this.multipleSelection[0]
      this.$confirm('是否删除吊篮', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await apis.delDl(data.id)
        this.$message.success('操作成功')
        this.init()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    async clickNotifier() { // 修改检查通知人事件
      const isOperate = this.optionItem.permissionNameList.includes('hanging_basket_operate')
      if (!isOperate) return this.$message.error('请联系管理员添加操作权限')
      if (this.multipleSelection.length == 0) return this.$message.warning('请选择一个吊篮项')
      this.getNotifY(this.multipleSelection[0].departmentCode)
      const data = this.multipleSelection[0]
      this.notifierForm = {
        userIdList: data.userIdList,
        id: data.id
      }
      this.changeNotifier = true
    },
    async confirmNotifier() {  // 修改通知人确定
      await apis.changeCheckNotice(this.notifierForm)
      this.$message.success('操作成功')
      this.changeNotifier = false
      this.init()
    },
    getNowFormatDate() {
      var currentDT = new Date()
      var y,m,date,hs,ms,ss
      y = currentDT.getFullYear() //四位整数表示的年份
      m = currentDT.getMonth() + 1 //月
      date = currentDT.getDate() //日
      hs = currentDT.getHours() //时
      ms = currentDT.getMinutes() //分
      ss = currentDT.getSeconds() //秒
      if(m.toString().length !=2 ){
        m = '0'+m.toString()
      }
      if(date.toString().length !=2 ){
        date = '0'+date.toString()
      }

      if(hs.toString().length !=2 ){
        hs = '0'+hs.toString()
      }
      if(ms.toString().length !=2 ){
        ms = '0'+ms.toString()
      }
      if(ss.toString().length !=2 ){
        ss = '0'+ss.toString()
      }
      const timer = y+"-"+m+"-"+date+" "+hs+":"+ms+":"+ss;
      return timer
    },
    // 打印
    topPrint(row) {
      this.isShowPrint = true
      let that = this
      apis.findByBasketId({id:row.id}).then(res=>{
        that.parentmsg=res
        that.parentmsg.type = '下'
        that.$nextTick(() => {
          that.$refs.codeItem.innerHTML = ''
          const params = row.id+','+that.parentmsg.type
          new QRCode(that.$refs.codeItem, {
            text: String(params), //二维码内容
            width: 350,
            height: 350,
            // colorDark: "#333333", //二维码颜色
            // colorLight: "#ffffff", //二维码背景色
          })
        }, 500)
      })
    },
    // 查看详情
    async handClick(row) {
      const isOperate = this.optionItem.permissionNameList.includes('hanging_basket_view')
      if (!isOperate) return this.$message.error('请联系管理员添加查看权限')
      const data = row
      await apis.findByBasketId({id:data.id}).then(async res=>{
        this.editForm= res
        // console.log(res,'res')
        this.isShowDetails = true
        // this.notifier = await apis.findByDepartmentId(data.departmentCode)
      })
    },
    // 审批查看详情
    async auditHandClick(row) {
      // const isOperate = this.optionItem.permissionNameList.includes('hanging_basket_view')
      // if (!isOperate) return this.$message.error('请联系管理员添加查看权限')
      const data = row
      await apis.findByBasketId({id:data.id}).then(async res=>{
        this.editForm= res
        this.isShowAuditDetails = true
      })
    },
    // 审批通过
    AuditConfirm() {
      apis.confirmDoStatus(this.editForm.id).then(()=>{
        this.$message.success(`审批成功`)
        this.init()
        this.isShowAuditDetails = false
      })
    },
    // 审批打回
    AuditRefused() {
      apis.confirmBack(this.editForm.id).then(()=>{
        this.$message.success(`已经打回`)
        this.init()
        this.isShowAuditDetails = false
      })
    },
    confirmChangeDoStatus(){
      let params = this.changeDoStatusForm
      params.id = this.selectItems.id
      apis.changeDoStatus(params).then(res=>{
        this.isShowChooseDoPeople = false
        this.changeDoStatusForm ={}
        this.checkBothFilelist = []
        this.$message.success(`${this.changeDoStatusForm.status===0?'启用':'停用'}成功`)
        this.init()
      })
    },
    handleAudit(row){
      this.$confirm('是否审批', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        apis.confirmDoStatus(row.id).then(res=>{
          this.$message.success(`审批成功`)
          this.init()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
  }
}
</script>
<style lang="stylus">
// @import "../../assets/var"
.nacelle-set
  // background-color $white
  .content
    height 100%
    padding 10px 20px
    .operation
      padding 0 0 10px 0
    .el-table .cell
      text-align center
    .el-table .is-left .cell
      text-align left !important
    .has-gutter
      .el-checkbox
        display none
  .one-xy .el-dialog
    .el-form-item {
      display flex
    }
    .el-input,
    .el-select>.el-input
      width 300px !important
    .el-dialog__header
      background #3396fb
      display flex
      padding 15px 20px
      .el-dialog__title
      .el-dialog__headerbtn .el-dialog__close
        color #ffffff
    .el-dialog__body
      padding 0 0 20px 0
      .el-form-item
        margin-bottom 22px
      .titleborder
        line-height 40px
        color #606266
        text-align left
        border-bottom 1px dashed #DCDFE6
        margin-bottom 10px
        padding-left 20px
.one-xy .el-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 !important;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
}

.el-dialog__body {
  overflow-y: auto;
  overflow-x hidden
}
.title {
  font-size 28px
  color:#000
}
.titles {
  margin-top 30px
  font-size 32px
  font-weight 600
}
.demo-form-inline{
  .form-choose-button {
    margin-left 5px
  }
}  .avatar-uploader .el-upload {
     border: 1px dashed #d9d9d9;
     border-radius: 6px;
     cursor: pointer;
     position: relative;
     overflow: hidden;
   }
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 222px;
  height: 222px;
  line-height: 222px;
  text-align: center;
}
.avatar {
  width: 222px;
  height: 222px;
  display: block;
}
.red{
  color 	#DC143C
}
.yellow{
  color #FFD700
}
.green{
  color #32CD32
}
.el-upload--picture-card{
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 222px;
  height: 222px;
  line-height: 222px;
}
</style>
